<template>
  <PublicLayout>
    <section class="section catalog-section">
      <div class="container">
        <div class="my-6">
          <div class="bg-white rounded-xl p-2 pt-7 text-center">
            <h1 class="text-2xl font-bold mb-6">{{ t('marketplace.checkout.buttons.checkout') }}</h1>

            <slot />
          </div>
        </div>
      </div>
    </section>
  </PublicLayout>
</template>

<script setup lang="ts">
import PublicLayout from "~/layouts/public.vue";

const {t} = useI18n();
</script>

<style scoped>

</style>
